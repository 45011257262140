import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import neihu5 from '../assets/images/neihu5.jpg'
import neihu8 from '../assets/images/neihu8.jpg'
import neihu10 from '../assets/images/neihu10.jpg'
import yonghe1 from '../assets/images/trig_002.webp'
import yonghe2 from '../assets/images/trig_003.webp'
import yonghe3 from '../assets/images/trig_004.webp'
import lesson1 from '../assets/images/00.jpg'
import lesson2 from '../assets/images/01.jpg'
import lesson3 from '../assets/images/02.jpg'
import month11 from '../assets/images/11month.png'
import googleMap from '../assets/images/google-maps.png'

const groupLesson = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 團體課程</title>
      <meta name="description" content="三健客團體課程" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>選擇地點</h1>
          </header>
          <a href="#yonghe" className="button" style={{ margin: '5px' }}>
            永和店團體課
          </a>
          <a href="#neihu" className="button" style={{ margin: '5px' }}>
            內湖店團體課
          </a>

          <header className="major" id="yonghe" style={{ marginTop: '20px' }}>
            <h3>永和店團體課</h3>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe1} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe3} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={lesson1} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={lesson2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={lesson3} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h3>📌『十一月份團體課表』</h3>
          <span className="image main">
            <img src={month11}></img>
          </span>
          <h3>📌團體課程有服務提供體驗課。</h3>
          <ul>
            (每位限一次，時間為60分鐘)
            <li>團體課程體驗價：250元/人。</li>
          </ul>
          <p>
            歡迎私訊、電話或來店諮詢報名一對一或團體課程！
            <br /> 電話：02-8942-2056
            <br />
            地址：新北市永和區民權路80號2樓
            <a
              href="https://goo.gl/maps/NsdQ8hUCa9U6pk8R7"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <img src={googleMap} style={{ width: '24px' }} alt="" />
            </a>
          </p>
          <p></p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3muscler/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/explore/locations/172586599964912/3musclers-/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <header className="major" id="neihu" style={{ marginTop: '20px' }}>
            <h3>內湖店團體課</h3>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu5} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu10} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu8} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h4>📌團體課收費：(一期8堂課、固定時段）：</h4>
          <ul>
            <li>
              TRX 團體班一期：3000元。
              <br />
              (上課時間為60分鐘)
            </li>
            <li>
              假日減重班一期：3600元。
              <br />
              (上課時間為90分鐘)
            </li>
          </ul>
          <h4>📌私人教練課和團體課程，皆提供體驗課。</h4>
          <ul>
            (每位限一次，時間為60分鐘)
            <li>私人教練課體驗價：500元/人。</li>
            <li>團體課程體驗價：250元/人。</li>
          </ul>
          <h4>📌提供免費諮詢及身體活動度檢測！(時間約30分鐘)</h4>
          <p>
            歡迎私訊、電話或來店諮詢報名一對一或團體課程！
            <br /> 電話：02-8751-1915
            <br />
            地址：台北市內湖區港華街18號
            <a
              href="https://goo.gl/maps/o2jCnwMGA9ZVmRqJ8"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <img src={googleMap} style={{ width: '24px' }} alt="" />
            </a>
          </p>
          <p></p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3musclers/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/explore/locations/172586599964912/3musclers-/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default groupLesson
